<script>
  import { gsap, ScrollTrigger } from "gsap/all";
  import { onMount } from "svelte";

  import Questions from "./Questions.svelte";
  import ChangeOrientation from "./ChangeOrientation.svelte";
  import SwipeRight from "./SwipeRight.svelte";
  import Law2Background from "./Law2Background.svelte";

  gsap.registerPlugin(ScrollTrigger);

  let mainContainer;
  let mainContainerWidth = 0;
  let slide0;
  let slide1;
  let windowWidth = 0;
  let windowHeight = 0;

  let isTouch = false;

  let law1imgWidth;

  $: mainContainerWidth && setTimeout(ScrollTrigger.refresh, 0);

  onMount(() => {
    isTouch = window.matchMedia("(pointer: coarse)").matches;

    new ResizeObserver((entries) => {
      if (!entries) return;
      const entry = entries[entries.length - 1];
      const width = (entry.borderBoxSize[0] || entry.borderBoxSize).inlineSize;
      console.log("RO: ", width);
      mainContainerWidth = width;
    }).observe(mainContainer, { box: "border-box" });

    let scrollTween;
    if (!isTouch)
      scrollTween = gsap.to(mainContainer, {
        x: () => -(mainContainerWidth - document.body.clientWidth),
        ease: "none",
        scrollTrigger: {
          trigger: document.body,
          start: "top top",
          end: "bottom bottom",
          scrub: 0.5,
          invalidateOnRefresh: true,
        },
      });

    gsap
      .timeline()
      .from(".slide-0 .sridhar", {
        y: "-100%",
        delay: 1,
      })
      .from(
        ".slide-0 .loa",
        {
          y: "-100%",
        },
        "-=50%"
      )
      .from(".slide-0 .explore", {
        opacity: 0,
        duration: 2,
      })
      .from(
        ".swipe-right",
        {
          opacity: 0,
          x: -50,
          ease: "elastic.out(1, 1)",
        },
        isTouch ? "<" : ">"
      );

    const scrollTrigParams = {
      horizontal: true,
      scrub: true,
      // markers: true,
    };

    if (isTouch) {
      scrollTrigParams.scroller = mainContainer;
    } else {
      scrollTrigParams.containerAnimation = scrollTween;
    }

    gsap.set(".law1ImgWrap", {
      clipPath: "circle(100%)",
    });
    gsap.from(".law1ImgWrap", {
      clipPath: "circle(0%)",
      duration: 3,
      scrollTrigger: {
        ...scrollTrigParams,
        trigger: slide1,
        start: "left center",
        fastScrollEnd: true,
        end: "+=100%",
      },
    });

    gsap
      .timeline({
        scrollTrigger: {
          ...scrollTrigParams,
          trigger: slide0,
          start: "left left",
          end: "+=3500",
        },
      })
      .to(".slide-0 .heading", {
        x: -50,
      })
      .to(
        ".slide-0 .explore",
        {
          x: -100,
        },
        "<"
      )
      .to(
        ".slide-0 .swipe-right",
        {
          x: 200,
        },
        "<"
      );

    gsap.from(".slide-1 .law1 div", {
      x: 50,
      opacity: 0,
      stagger: 0.2,
      scrollTrigger: {
        ...scrollTrigParams,
        trigger: ".law1ImgWrap",
        start: "center center",
        end: "+=40%",
      },
    });

    gsap.set(slide1, { backgroundColor: "#f0663b" });
    gsap.to(slide1, {
      backgroundColor: "#df4b4b",
      scrollTrigger: {
        ...scrollTrigParams,
        trigger: ".law1ImgWrap",
        start: "right left",
        end: "+=50%",
      },
    });

    gsap
      .timeline({
        scrollTrigger: {
          ...scrollTrigParams,
          trigger: ".law1",
          start: "center center",
          end: "+=40%",
        },
      })
      .from(".slide-1 .sep", {
        scaleY: 0,
      })
      .from(".slide-1 .question-wrap", {
        y: -100,
        opacity: 0,
      });

    gsap
      .timeline({
        scrollTrigger: {
          ...scrollTrigParams,
          trigger: ".slide-2 .start .big",
          start: "center center",
          endTrigger: ".slide-2 .finish .big",
          end: "center center",
          onEnter: () => {
            gsap.set(
              ".slide-2 .finish .bg svg path, .slide-2 .finish .get-notified",
              {
                opacity: 0,
              }
            );
            gsap.set(".slide-2 .credits", {
              opacity: 0,
              x: 5,
            });
          },
          onLeave: () =>
            gsap
              .timeline()
              .to(
                ".slide-2 .finish .bg svg path, .slide-2 .finish .get-notified",
                {
                  opacity: 1,
                  stagger: 0.1,
                  delay: 0.5,
                }
              )
              .to(".slide-2 .credits", {
                opacity: 1,
                x: 0,
                delay: 1,
              }),
        },
      })
      .to(".slide-2 .start", {
        opacity: 0,
      })
      .to(".slide-2 .finish", {
        opacity: 1,
      });
  });
</script>

<svelte:window bind:innerWidth={windowWidth} bind:innerHeight={windowHeight} />

{#if windowWidth < windowHeight && windowWidth < 600}
  <ChangeOrientation />
{/if}
{#if !isTouch}
  <div
    class="scroller"
    style="height: {mainContainerWidth - windowWidth + windowHeight}px"
  />
{/if}
<div bind:this={mainContainer} class="main-container">
  <!-- Slide 0 -->
  <div class="slide-0" bind:this={slide0}>
    <div class="heading">
      <div class="hide-overflow">
        <div class="sridhar">Sridhar's</div>
      </div>
      <div class="hide-overflow">
        <div class="loa">Laws Of Amrita</div>
      </div>
    </div>
    <div class="video-bg">
      <div class="overlay" />
      <video src="media/amvideo.mp4" autoplay muted loop />
    </div>
    <SwipeRight />
    <div
      class="explore"
      on:click|preventDefault={() => window.scrollTo(0, windowWidth)}
    >
      <div class="text">Explore</div>
      <svg
        class="icon"
        width="17"
        height="31"
        viewBox="0 0 17 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0L10.2 15.5L0 31H6.8L17 15.5L6.8 0H0Z" fill="white" />
      </svg>
    </div>
  </div>

  <!-- Slide 1 -->
  <div bind:this={slide1} class="slide-1">
    <div class="law1ImgWrap" bind:clientWidth={law1imgWidth}>
      <picture>
        <source srcset="media/SlaT_1.webp" type="image/webp" />
        <img src="media/SlaT_1.png" alt="Sridhar's first law" />
      </picture>
    </div>
    <div class="law1" style="width: {windowWidth - law1imgWidth}px">
      <div class="heading">Law 1</div>
      <div class="wording">
        For any logical/valid question you have, do not expect an answer.
      </div>
    </div>
    <div class="sep" />
    <div class="question-section">
      <div style="flex-grow: 1" />
      <div class="question-wrap">
        <Questions />
      </div>
      <div style="flex-grow: 2">
        <div class="triangle" />
      </div>
    </div>
  </div>

  <!-- Slide 2 -->
  <div class="slide-2">
    <div class="section start">
      <div class="big">Law 2...</div>
    </div>
    <div class="section finish">
      <div class="big">
        <div class="text">...is being theorized.</div>
        <div class="bg">
          <Law2Background />
        </div>
        <div
          class="get-notified"
          on:click={() => window.Notification.requestPermission()}
        >
          Get notified on release
        </div>
      </div>
      <div class="credits">
        Site design by
        <a href="https://github.com/radiantly">@radiantly</a>
      </div>
    </div>
  </div>
</div>

<style>
  .main-container {
    display: flex;
    overflow: hidden;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  .hide-overflow {
    overflow: hidden;
  }

  @media (pointer: coarse) {
    .main-container {
      overflow: scroll;
      width: 100vw;
    }
  }

  /* Slide 0 */

  .slide-0 {
    min-width: 100vw;
    height: 100vh;
    display: flex;
    position: relative;
  }

  .slide-0 .heading {
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: min(200px, 5vw);
  }

  .slide-0 .heading .sridhar {
    color: var(--yellow);
    font-size: 48px;
    line-height: 53px;
  }

  .slide-0 .heading .loa {
    font-size: 96px;
    line-height: 106px;
    color: white;
  }

  .video-bg {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    pointer-events: none;
  }
  .video-bg .overlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%;
    width: 100%;
  }
  .video-bg video {
    object-fit: cover;
    height: 100vh;
    width: 100vw;
  }

  .explore {
    font-weight: 500;
    text-transform: uppercase;
    border: 4px solid white;
    display: flex;
    align-items: center;
    padding: 0 50px;
    gap: 20px;
    position: absolute;
    bottom: 50px;
    right: min(100px, 5vw);
    height: 80px;
    cursor: pointer;
    transition: all 0.25s ease;
  }
  .explore .text {
    /* transform: translateY(2px); */
    font-size: 30px;
  }

  .explore .icon path {
    transition: all 0.25s ease;
  }

  .explore:hover {
    background-color: white;
    color: #333;
  }

  .explore:hover .icon path {
    fill: #333;
  }

  /* Slide 1 */

  .slide-1 {
    height: 100vh;
    display: flex;
  }
  .law1ImgWrap {
    width: 90vh;
    padding: 0 min(50px, 5vw);
    align-self: flex-end;
    flex-shrink: 0;
  }

  .law1ImgWrap img {
    width: 100%;
    height: 100%;
  }

  .law1 {
    font-weight: 700;
    padding: min(30px, 5vw);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 600px;
    flex-shrink: 0;
  }

  .law1 .heading {
    font-size: 5rem;
    padding: min(50px, 5vh);
    text-transform: uppercase;
  }

  .law1 .wording {
    font-size: 2rem;
    max-width: 600px;
    line-height: 1.2;
  }
  .slide-1 .sep {
    height: 80vh;
    align-self: center;
    background-color: white;
    width: 5px;
    border-radius: 3px;
    margin: 20px;
  }

  .slide-1 .question-section {
    width: 100vw;
    height: 100%;
    display: flex;
  }

  .slide-1 .question-wrap {
    align-self: center;
    flex-shrink: 0;
    padding: 10px 50px;
  }

  .slide-1 .question-section .triangle {
    background-color: white;
    float: right;
    width: 50%;
    height: 100%;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: translateX(1px);
  }

  .slide-2 {
    background-color: white;
    width: 100vw;
    position: relative;
    height: 100%;
  }

  .slide-2 .start {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 5vw;
  }

  .slide-2 .finish {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100%;
    opacity: 0;
  }

  .slide-2 .big {
    font-weight: 700;
    color: #313131;
    font-size: 96px;
    text-align: center;
    position: relative;
  }

  .slide-2 .text {
    z-index: 10;
    position: relative;
  }

  .slide-2 .big .bg {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: min(75vw, 700px);
    height: 40vh;
    z-index: 9;
  }

  .slide-2 .get-notified::before {
    content: "";
    background-color: #00c1ec;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transform: scaleY(0);
    transform-origin: center bottom;
    transition: all 0.25s ease;
  }

  .slide-2 .get-notified {
    position: absolute;
    bottom: -75%;
    font-size: 20px;
    transform: translateX(-50%);
    left: 50%;
    color: #00c1ec;
    cursor: pointer;
    border-bottom: 3px solid;
    transition: all 0.25s ease;
  }

  .slide-2 .get-notified:hover {
    color: white;
  }
  .slide-2 .get-notified:hover::before {
    transform: scaleY(1);
  }

  .slide-2 .credits {
    position: absolute;
    top: 12px;
    right: 23px;
    color: #f7867e;
    font-family: Graduate;
    transform: rotateZ(-90deg);
    transform-origin: bottom right;
  }

  .slide-2 .credits a {
    color: inherit;
    text-decoration: none;
  }

  @media screen and (max-width: 1000px) {
    .slide-0 .heading .sridhar {
      font-size: 36px;
    }
    .slide-0 .heading .loa {
      font-size: 54px;
      line-height: 60px;
    }
    .slide-0 .explore {
      display: none;
    }
    .slide-1 .law1 {
      min-width: 400px;
    }
    .slide-1 .law1 .heading {
      font-size: 3.5rem;
    }
    .slide-1 .law1 .wording {
      font-size: 1.5rem;
      max-width: 400px;
    }
    .slide-2 .big {
      font-size: 70px;
    }
    .slide-2 .credits {
      visibility: hidden;
    }
  }
</style>
