<script>
  import { gsap } from "gsap/all";
  import { onMount } from "svelte";

  onMount(() =>
    gsap.from("#change-orientation path", {
      opacity: 0,
      stagger: 1,
      repeat: -1,
      repeatDelay: 5,
    })
  );
</script>

<div class="overlay">
  <svg
    width="148"
    height="148"
    viewBox="0 0 148 148"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    id="change-orientation"
  >
    <path
      d="M66.4875 0H12.3125C9.04737 0 5.91563 1.29722 3.60566 3.60566C1.29708 5.91535 0 9.04736 0 12.3125V135.437C0 138.703 1.29722 141.834 3.60566 144.144C5.91535 146.453 9.04737 147.75 12.3125 147.75H66.4875C69.7526 147.75 72.8844 146.453 75.1943 144.144C77.5029 141.835 78.8 138.703 78.8 135.437V12.3125C78.8 9.04736 77.5028 5.91563 75.1943 3.60566C72.8847 1.29708 69.7526 0 66.4875 0ZM73.875 135.437C73.875 137.396 73.0967 139.276 71.7115 140.661C70.3264 142.046 68.4464 142.825 66.4876 142.825H12.3126C10.3536 142.825 8.47367 142.047 7.08876 140.661C5.70385 139.276 4.92528 137.396 4.92528 135.437V12.3125C4.92528 10.3535 5.7036 8.47353 7.08876 7.08862C8.47393 5.70371 10.3539 4.92514 12.3126 4.92514H19.7001V7.38764C19.7001 8.04064 19.9596 8.66727 20.4213 9.12898C20.883 9.59069 21.5096 9.85014 22.1626 9.85014H56.6376C57.2906 9.85014 57.9173 9.5907 58.379 9.12898C58.8407 8.66726 59.1001 8.04064 59.1001 7.38764V4.92514H66.4876C68.4466 4.92514 70.3266 5.70346 71.7115 7.08862C73.0964 8.47379 73.875 10.3538 73.875 12.3125V135.437Z"
    />
    <path
      d="M94.117 20.3892C102.773 21.8667 110.836 25.7628 117.372 31.6277C123.908 37.4927 128.651 45.0879 131.054 53.5353L124.356 49.5953C123.792 49.1852 123.082 49.0335 122.4 49.1764C121.718 49.3204 121.13 49.7459 120.779 50.3483C120.428 50.9507 120.35 51.6741 120.562 52.337C120.775 53.001 121.258 53.543 121.894 53.8299L132.482 60.0852C132.852 60.3139 133.279 60.4326 133.714 60.4304H134.354C134.992 60.2941 135.534 59.8775 135.831 59.297L142.086 48.7083C142.772 47.5353 142.402 46.0303 141.249 45.3103C140.064 44.6331 138.554 45.0267 137.851 46.1963L135.339 50.4817C132.443 41.5441 127.151 33.5717 120.039 27.4332C112.928 21.2936 104.268 17.2227 95.0032 15.6616C87.1232 15.367 95.0032 15.6617 87.1232 15.3669C86.036 16.1826 85.815 17.7261 86.6307 18.8144C94.117 20.3892 86.6307 18.8144 94.117 20.3892Z"
    />
    <path
      d="M135.437 68.95H88.65C87.2901 68.95 86.1875 70.0526 86.1875 71.4125C86.1875 72.7724 87.2901 73.875 88.65 73.875H135.437C137.396 73.875 139.276 74.6533 140.661 76.0385C142.046 77.4236 142.825 79.3036 142.825 81.2624V88.6499H140.362C139.002 88.6499 137.9 89.7525 137.9 91.1124V125.587C137.9 126.24 138.159 126.867 138.621 127.329C139.083 127.79 139.709 128.05 140.362 128.05H142.825V135.437C142.825 137.396 142.047 139.276 140.661 140.661C139.276 142.046 137.396 142.825 135.437 142.825H83.725C82.3651 142.825 81.2625 143.927 81.2625 145.287C81.2625 146.647 82.3651 147.75 83.725 147.75H135.437C138.703 147.75 141.834 146.452 144.144 144.144C146.453 141.834 147.75 138.702 147.75 135.437V81.2622C147.75 77.9971 146.453 74.8653 144.144 72.5554C141.835 70.2468 138.703 68.9497 135.437 68.9497V68.95Z"
    />
  </svg>
</div>

<style>
  .overlay {
    position: fixed;
    z-index: 666;
    top: 0;
    left: 0;
    background-color: var(--yellow);
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .overlay svg path {
    fill: #151515;
  }
</style>
