<script>
  const questions = [
    {
      question:
        "Why are you only allowed to swim in the first 1/4th part of the swimming pool?",
      desc: "If you go for a swim at the swimming pool in the campus, the person there only allows you to swim in the first 1/4th of the pool. As an expert swimmer, why impose such a restriction?",
    },
    {
      question: "Why is the curd salad always so salty?",
      desc: "In the mess, the curd salad (served with biriyani) is always so much more saltier than the curd served alone. Why?",
    },
    {
      question: "Why isn't real ice cream sold at the canteen?",
      desc: "At the canteens at college, frozen dessert from companies like Kwality walls, Dairy Day and Cornetto is sold as ice cream. Compared to actual ice cream, frozen dessert is much worse for health. Why not switch to the real thing?",
    },
    {
      question: "Why are colour printouts in the campus so expensive?",
      desc: "When calculating the price a colour print costs based on toner prices, it comes to around 50 paise per page. Why is the computer lab at Academic Block 1 (the only place you can get a colour printout in the campus) charging Rs 10 per page?",
    },
    {
      question:
        "Why isn't there an additional billing counter at the night canteen?",
      desc: "The night canteen next to gauthama bhavanam is quite crowded at night, with huge queues at both the billing counter and the dosa point. With an additional counter, the queue size could be decreased by a lot.",
    },
    {
      question: "Why are only written lab records accepted?",
      desc: "The average typing speed is around 70-80 wpm (words per minute) whereas the average writing speed is only around 25 wpm. If typed and printed documents were accepted (like universities abroad!), the time would be saved would be around 2-3x.",
    },
  ];

  import gsap from "gsap";
  import { onMount } from "svelte";

  let qIndex = 0;

  onMount(() =>
    gsap
      .timeline({
        repeat: -1,
      })
      .to(".q-title", {
        y: -20,
        opacity: 0,
      })
      .to(
        ".indicator .line",
        {
          scaleX: 0,
        },
        "<"
      )
      .to(
        ".q-desc",
        {
          y: 20,
          opacity: 0,
          onComplete: () => (qIndex = (qIndex + 1) % questions.length),
        },
        "<"
      )
      .to(".q-wrap div", {
        y: 0,
        opacity: 1,
      })
      .to(".indicator .line", {
        scaleX: 1,
        duration: 10,
      })
  );
</script>

<div class="q-wrap">
  <div class="q-title">{questions[qIndex].question}</div>
  <div class="q-desc">{questions[qIndex].desc}</div>
  <div class="indicator">
    <div class="line" />
  </div>
</div>

<style>
  .q-wrap {
    max-width: min(1000px, 85vw);
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    line-height: 1.2;
    gap: 3rem;
  }

  .indicator {
    display: flex;
    align-items: center;
  }

  .indicator .line {
    width: 50%;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 2px;
    transform-origin: left center;
  }

  .q-title {
    text-transform: uppercase;
    font-size: 54px;
  }

  .q-desc {
    font-size: 28px;
    line-height: 1.3;
  }

  @media screen and (max-width: 1000px) {
    .q-wrap {
      gap: 1.5rem;
    }
    .q-title {
      font-size: 2rem;
      font-weight: 500;
      text-transform: none;
    }
    .q-desc {
      font-size: 1.4rem;
    }
  }
</style>
