<svg
  class="swipe-right"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 203.87 74.17"
>
  <g class="arrows">
    <path
      class="serious-arrow"
      d="M150.13,0H166l26.4,37.09L166,74.17H150.13l26.41-37.08Z"
    />
    <path
      class="arrow"
      d="M119,0h15.84l26.4,37.09-26.4,37.08H119l26.4-37.08Z"
    />
  </g>
  <g class="text">
    <path
      d="M18.53,40.09h7.82q4.08,0,6,1.9c1.24,1.26,1.87,3.2,1.87,5.83v2.06c0,3.49-1.16,5.7-3.46,6.63v.09A4.12,4.12,0,0,1,33.43,59a11.84,11.84,0,0,1,.8,4.76v5.9c0,1,0,1.74.09,2.33a5.65,5.65,0,0,0,.48,1.75H29.42A7.51,7.51,0,0,1,29,72.15a23.76,23.76,0,0,1-.1-2.59V63.42a5.2,5.2,0,0,0-.74-3.22,3.14,3.14,0,0,0-2.57-.91H23.81v14.4H18.53Zm7.2,14.4a3.11,3.11,0,0,0,2.37-.82,3.82,3.82,0,0,0,.8-2.73V48.35a4.26,4.26,0,0,0-.65-2.64,2.43,2.43,0,0,0-2-.82h-2.4v9.6Z"
    />
    <path d="M39.25,40.09h5.28v33.6H39.25Z" />
    <path
      d="M51,72a9,9,0,0,1-2-6.27V48.06a9,9,0,0,1,2-6.27,7.6,7.6,0,0,1,5.86-2.18,7.58,7.58,0,0,1,5.85,2.18,8.9,8.9,0,0,1,2,6.27v2.88h-5V47.72c0-2.21-.92-3.31-2.74-3.31s-2.74,1.1-2.74,3.31V66.11c0,2.17.92,3.26,2.74,3.26s2.74-1.09,2.74-3.26V59.53H57.1v-4.8h7.63v11a8.9,8.9,0,0,1-2,6.27,7.58,7.58,0,0,1-5.85,2.18A7.6,7.6,0,0,1,51,72Z"
    />
    <path
      d="M69.18,40.09h5.28V53.77h5.66V40.09H85.4v33.6H80.12V58.57H74.46V73.69H69.18Z"
    />
    <path d="M95.38,44.89H89.85v-4.8h16.32v4.8h-5.52v28.8H95.38Z" />
    <g class="swipe">
      <path
        d="M20.5,32.38a9,9,0,0,1-2-6.27V24.19h5V26.5q0,3.26,2.74,3.26a2.58,2.58,0,0,0,2-.79A3.89,3.89,0,0,0,29,26.4a7.11,7.11,0,0,0-1-3.72,17.63,17.63,0,0,0-3.55-3.86,21.13,21.13,0,0,1-4.56-5.21,10.68,10.68,0,0,1-1.3-5.26,8.8,8.8,0,0,1,2-6.17A7.6,7.6,0,0,1,26.5,0a7.29,7.29,0,0,1,5.73,2.18,9.13,9.13,0,0,1,2,6.27V9.84h-5V8.11a3.86,3.86,0,0,0-.67-2.52,2.43,2.43,0,0,0-2-.79C24.78,4.8,23.9,5.87,23.9,8a6.29,6.29,0,0,0,1,3.4,18.6,18.6,0,0,0,3.57,3.84A19.67,19.67,0,0,1,33,20.5,11.48,11.48,0,0,1,34.27,26a9,9,0,0,1-2,6.33,7.67,7.67,0,0,1-5.93,2.21A7.42,7.42,0,0,1,20.5,32.38Z"
      />
      <path
        d="M36.05.48h5.09L43.49,26.3h.09L46.08.48h5.76l2.5,25.82h.09L56.78.48h4.56l-3.4,33.6H51.36L49,11.42h-.1l-2.4,22.66h-7Z"
      />
      <path d="M64,.48h5.28v33.6H64Z" />
      <path
        d="M73.25.48H81c2.63,0,4.59.7,5.91,2.11s2,3.47,2,6.19V12.1q0,4.08-2,6.19C85.61,19.7,83.65,20.4,81,20.4H78.53V34.08H73.25ZM81,15.6a2.49,2.49,0,0,0,2-.72,3.67,3.67,0,0,0,.65-2.45v-4A3.67,3.67,0,0,0,83,6a2.49,2.49,0,0,0-2-.72H78.53V15.6Z"
      />
      <path
        d="M91.77.48h14.4v4.8H97.05v8.88h7.25V19H97.05V29.28h9.12v4.8H91.77Z"
      />
    </g>
    <g class="scroll">
      <path
        d="M2,32.38a9.08,9.08,0,0,1-2-6.27V24.19H5V26.5q0,3.26,2.74,3.26a2.58,2.58,0,0,0,2-.79,3.89,3.89,0,0,0,.69-2.57,7.11,7.11,0,0,0-1-3.72A17.63,17.63,0,0,0,6,18.82a21.57,21.57,0,0,1-4.56-5.21A10.68,10.68,0,0,1,.1,8.35a8.8,8.8,0,0,1,2-6.17A7.61,7.61,0,0,1,8,0,7.27,7.27,0,0,1,13.7,2.18a9.13,9.13,0,0,1,2,6.27V9.84h-5V8.11A3.85,3.85,0,0,0,10,5.59,2.41,2.41,0,0,0,8,4.8C6.26,4.8,5.38,5.87,5.38,8a6.28,6.28,0,0,0,1,3.4,18.7,18.7,0,0,0,3.58,3.84A20,20,0,0,1,14.5,20.5,11.59,11.59,0,0,1,15.74,26a9,9,0,0,1-2,6.33,7.66,7.66,0,0,1-5.92,2.21A7.44,7.44,0,0,1,2,32.38Z"
      />
      <path
        d="M20.42,32.4a8.7,8.7,0,0,1-2-6.1v-18a8.7,8.7,0,0,1,2-6.1A7.49,7.49,0,0,1,26.21,0,7.47,7.47,0,0,1,32,2.16a8.65,8.65,0,0,1,2,6.1v3.55H29V7.92Q29,4.8,26.35,4.8T23.71,7.92V26.69c0,2,.88,3.07,2.64,3.07s2.64-1,2.64-3.07V21.55h5V26.3a8.65,8.65,0,0,1-2,6.1,7.47,7.47,0,0,1-5.78,2.16A7.49,7.49,0,0,1,20.42,32.4Z"
      />
      <path
        d="M37.15.48H45q4.08,0,6,1.9T52.8,8.21v2.06q0,5.24-3.46,6.63V17a4.16,4.16,0,0,1,2.72,2.35,12,12,0,0,1,.79,4.76V30c0,1,0,1.74.09,2.33a6.26,6.26,0,0,0,.48,1.75H48.05a8.28,8.28,0,0,1-.39-1.54A24.56,24.56,0,0,1,47.57,30V23.81a5.19,5.19,0,0,0-.75-3.22,3.12,3.12,0,0,0-2.56-.91H42.43v14.4H37.15Zm7.2,14.4a3.15,3.15,0,0,0,2.38-.82,3.82,3.82,0,0,0,.79-2.73V8.74a4.26,4.26,0,0,0-.65-2.64,2.43,2.43,0,0,0-2-.82h-2.4v9.6Z"
      />
      <path
        d="M58.18,32.35a8.77,8.77,0,0,1-2.07-6.24V8.45a8.77,8.77,0,0,1,2.07-6.24A7.75,7.75,0,0,1,64.13,0a7.76,7.76,0,0,1,6,2.21,8.77,8.77,0,0,1,2.06,6.24V26.11a8.77,8.77,0,0,1-2.06,6.24,7.76,7.76,0,0,1-6,2.21A7.75,7.75,0,0,1,58.18,32.35Zm8.68-5.9V8.11q0-3.31-2.73-3.31T61.39,8.11V26.45q0,3.32,2.74,3.31T66.86,26.45Z"
      />
      <path d="M75.69.48H81v28.8h8.68v4.8h-14Z" />
      <path d="M92.21.48h5.28v28.8h8.68v4.8h-14Z" />
    </g>
  </g>
</svg>

<style>
  .swipe-right {
    height: 40px;
    position: absolute;
    top: 50px;
    right: min(100px, 5vw);
    fill: white;
    overflow: visible;
  }

  .swipe-right .swipe {
    display: none;
  }

  @media (pointer: coarse) {
    .swipe-right .scroll {
      display: none;
    }
    .swipe-right .swipe {
      display: initial;
    }
  }
</style>
